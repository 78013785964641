import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { Workspace } from '#/packages/workspace/types/Workspace';
import backendHttpClient from '#/src/api/backendHttpClient';

export type WorkspaceQueryKey = [string, Workspace['id'] | null];

export const getWorkspaceQueryKey = (workspaceId: Workspace['id'] | null): WorkspaceQueryKey => [
  'workspace',
  workspaceId
];

export const getWorkspaceQueryUrl = (workspaceId?: Workspace['id'] | null) =>
  workspaceId ? `/workspace/${workspaceId}` : '';

export type AdAccountsQueryKey = [string, Workspace['id'] | undefined];

export function getAdAccountsQueryKey(
  workspaceId: Workspace['id'] | undefined
): AdAccountsQueryKey {
  return ['getAdAccounts', workspaceId];
}

export function getAdAccountsQueryUrl(workspaceId: Workspace['id'] | undefined) {
  return workspaceId ? `${getWorkspaceQueryUrl(workspaceId)}/ad-account` : null;
}

export const useWorkspaceQuery = (workspaceId: Workspace['id'] | null, opts?: any) => {
  return useQuery<Workspace | null, AxiosError, Workspace | null, WorkspaceQueryKey>({
    queryKey: getWorkspaceQueryKey(workspaceId),
    queryFn: ({ queryKey }) =>
      backendHttpClient.get(getWorkspaceQueryUrl(queryKey[1] as Workspace['id'] | null)),
    enabled: Boolean(workspaceId),
    ...opts
  });
};
