import { AdsAccount } from '#/packages/ads-account/types/AdsAccount';
import type { AdBreakdownSpecs } from '#/src/types/AdBreakdownModel';
import { ChartType } from '#/src/types/ChartType';

export enum ContentItemType {
  ROOT = 'ROOT',
  BOARD = 'BOARD',
  BREAKDOWN_REPORT = 'BREAKDOWN_REPORT',
  ADGEN_CHAT = 'ADGEN_CHAT'
}
export const ContentItemTypeLabels: Record<ContentItemType, string> = {
  ROOT: 'Root',
  BOARD: 'Board',
  BREAKDOWN_REPORT: 'Report',
  ADGEN_CHAT: 'AdGen Chat'
};

export interface ContentItem {
  id: string;
  createdAt: number;
  updatedAt: number;
  deletedAt: number | null;
  children: string[];
  workspaceId: string;
  description: string;
  data?: AdBreakdownSpecs;
  specs: AdBreakdownSpecs;
  isRoot: boolean;
  isFolder: boolean;
  name: string;
  icon?: string | null;
  publicKey: ContentItemPublicKey | null;
  published: boolean;
  parentId: string | null;

  temp?: boolean; // fixme
  type: ContentItemType;
}

export interface SidebarContentItem {
  id: string;
  name: string;
  isFolder: boolean;
  isRoot: boolean;
  children: string[];
  selectedVisualization?: ChartType;
  parentPath?: string[];
  isFetching?: boolean;
}

export type ContentItemPublicKey = string;

export interface ContentItemByPublicKey {
  item: ContentItem;
  adAccount: AdsAccount;
  adAccounts?: AdsAccount[];
  publicChildren: string[];
}

export interface ContentItemWithParentId extends ContentItem {
  parentId: string;
}
