import Link, { LinkProps } from 'next/link';
import React from 'react';
import { Button, ButtonProps } from '#/packages/ui';

export interface NavButtonProps extends ButtonProps, Omit<LinkProps, 'href' | 'onClick'> {
  to?: string;
}

export const NavButton: React.FC<NavButtonProps> = (props) => {
  const { to, ...rest } = props;

  return (
    <Button
      {...rest}
      component={props.component || (to ? Link : undefined)}
      href={to || props.href}
    />
  );
};
