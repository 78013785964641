import { type AnalyticsBrowser, Context, EventProperties } from '@segment/analytics-next';
import { type FetchUserAttributesOutput } from 'aws-amplify/auth';

export enum AuthEvent {
  SIGNUP = 'signup',
  LOGIN = 'login'
}

export enum AuthProvider {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook'
}

export interface AnalyticsClient extends AnalyticsBrowser {
  trackExperiment: (experimentName: string, enabled: boolean) => Promise<Context>;
  trackAuth: (event: AuthEvent, params: EventProperties) => Promise<Context>;
  identifyUser: (userAttributes: FetchUserAttributesOutput) => Promise<undefined>;
}

export type AnalyticsProviderProps = {
  writeKey: string | undefined;
  children: React.ReactNode;
};
