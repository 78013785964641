import { Key } from 'react';
import { ContentItem } from '../types/ContentItem';

export const sortItemsByRoot = (root?: string[], contentItemsMap?: Map<Key, ContentItem>) => {
  if (!root || !contentItemsMap) {
    return [];
  }

  const sidebarItems: ContentItem[] = [];
  const addedIds = new Set();

  const digChildren = (children: string[]) => {
    children.forEach((childId) => {
      const childItem = contentItemsMap.get(childId);

      if (childItem && !addedIds.has(childId)) {
        sidebarItems.push(childItem);
        addedIds.add(childId);

        digChildren(childItem.children);
      }
    });
  };

  root.forEach((id) => {
    const item = contentItemsMap.get(id);

    if (item && !addedIds.has(id)) {
      sidebarItems.push(item);
      addedIds.add(id);

      digChildren(item.children);
    }
  });

  return sidebarItems;
};
