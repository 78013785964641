import { UseQueryResult, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { ContentItem } from '#/packages/content-item/types/ContentItem';
import type { Workspace } from '#/packages/workspace/types/Workspace';
import httpClient from '#/src/api/backendHttpClient';

export type ContentItemsQueryKey = [string, Workspace['id'] | undefined];

export const getContentItemsQueryKey = (workspaceId?: string): ContentItemsQueryKey => [
  'contentItems',
  workspaceId
];
export const getContentItemsQueryUrl = (workspaceId?: string) =>
  `/content-item?workspaceId=${workspaceId}`;

export const useContentItemsQuery = (
  workspaceId?: string,
  opts?: { enabled?: boolean }
): UseQueryResult<ContentItem[]> => {
  return useQuery<ContentItem[], AxiosError, ContentItem[], ContentItemsQueryKey>({
    queryKey: getContentItemsQueryKey(workspaceId),
    queryFn: ({ queryKey }) => httpClient.get(getContentItemsQueryUrl(queryKey[1])),
    ...opts,
    enabled: opts?.enabled !== false && Boolean(workspaceId)
  });
};
