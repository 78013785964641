'use client';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import {
  getWorkspaceQueryKey,
  useWorkspaceQuery
} from '#/packages/workspace/queries/useWorkspaceQuery';
import type { Workspace } from '#/packages/workspace/types/Workspace';
import { SessionContext } from '#/src/context/session/SessionContext';

const useWorkspace = (): [
  Workspace | null | undefined,
  (workspace: Workspace | null) => void,
  boolean
] => {
  const context = useContext(SessionContext);
  const queryClient = useQueryClient();

  const { workspaceId, setWorkspaceId } = context;
  const { data: workspace, isLoading } = useWorkspaceQuery(workspaceId);

  const setWorkspace = useCallback((workspace: Workspace | null) => {
    if (workspace) {
      queryClient.setQueryData(getWorkspaceQueryKey(workspace.id), workspace);
    }

    setWorkspaceId(workspace?.id || null);
  }, []);

  return [workspace, setWorkspace, isLoading];
};

export const useWorkspaceId = (): Workspace['id'] | null => {
  const contextId: Workspace['id'] | null = useSessionWorkspaceId();
  const workspaceId: Workspace['id'] | undefined = useWorkspace()[0]?.id;

  return workspaceId || contextId;
};

export const useSessionWorkspaceId = () => {
  const context = useContext(SessionContext);

  const { workspaceId } = context;

  return workspaceId;
};

export { useWorkspace };
