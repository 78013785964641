import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { getContentItemsQueryKey } from '#/packages/content-item/queries/useContentItemsQuery';
import type { ContentItem } from '#/packages/content-item/types/ContentItem';
import { Workspace } from '#/packages/workspace/types/Workspace';
import httpClient from '#/src/api/backendHttpClient';
import { useSessionWorkspaceId } from '#/src/context/session/hooks/useWorkspace';
import { defaultAxiosRetry } from '#/src/utils/defaultAxiosRetry';

export type ContentItemQueryKey = [string, ContentItem['id'] | undefined];
export const getContentItemQueryKey = (id?: ContentItem['id']): ContentItemQueryKey => [
  'getContentItem',
  id
];

export const getContentItemQueryUrl = (id?: ContentItem['id']) => `/content-item/${id}`;

export function useContentItemQuery(
  id?: ContentItem['id'],
  fallbackWorkspaceId?: Workspace['id']
): UseQueryResult<ContentItem> {
  const queryClient = useQueryClient();
  const workspaceId = useSessionWorkspaceId() || fallbackWorkspaceId;

  const initialData = workspaceId
    ? (queryClient.getQueryData(getContentItemsQueryKey(workspaceId)) as ContentItem[])?.find(
        (item) => item.id === id
      )
    : undefined;

  return useQuery<ContentItem, AxiosError, ContentItem, ContentItemQueryKey>({
    queryKey: getContentItemQueryKey(id),
    queryFn: ({ queryKey }) => httpClient.get(getContentItemQueryUrl(queryKey[1])),
    enabled: Boolean(id),
    retry: defaultAxiosRetry,
    initialData
  });
}
