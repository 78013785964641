import { ContentItem, ContentItemType } from '#/packages/content-item/types/ContentItem';

export const isTempContentItem = (item?: ContentItem | null) => item?.temp;

export const isRootContentItem = (item?: ContentItem | null) =>
  item?.isRoot || item?.type === ContentItemType.ROOT;

export const isFolderContentItem = (item?: Pick<ContentItem, 'isFolder' | 'type'> | null) => {
  return item?.isFolder || item?.type === ContentItemType.BOARD;
};

export const isReportContentItem = (item?: ContentItem | null) => {
  return item?.type === ContentItemType.BREAKDOWN_REPORT;
};

export const UNTITLED_BOARD = 'Untitled board';
export const UNTITLED_REPORT = 'Untitled report';

export const contentItemName = (item?: Pick<ContentItem, 'name' | 'isFolder' | 'type'> | null) => {
  const isBoard = isFolderContentItem(item);

  return item?.name || (isBoard ? UNTITLED_BOARD : UNTITLED_REPORT);
};
