'use client';

import { datadogRum } from '@datadog/browser-rum';
import { QueryClientProvider } from '@tanstack/react-query';
import { FlagProvider, type IMutableContext, type IToggle } from '@unleash/nextjs';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { AnalyticsProvider } from '#/packages/analytics';
import { ChatWithDataProvider } from '#/src/components/ChatWithData/ChatWithDataContext';
import amplifyConfig from '#/src/config/amplifyConfig';
import { SessionProvider, SessionProviderProps } from '#/src/context/session/SessionContext';
import { UserflowProvider } from '#/src/context/userflow';
import { getQueryClient } from '#/src/utils/getQueryClient';
import { ContentItemActionsProvider } from '../../packages/content-item/components/ContentItemActionsProvider';
import { ToastifyProvider } from '../context/toastify';

Amplify.configure(amplifyConfig, { ssr: true });

// import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
// import { CookieStorage } from 'aws-amplify/utils';
// import cookieStorageConfig from '#/src/config/cookieStorageConfig';
// cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage(cookieStorageConfig));

const NEXT_PUBLIC_VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || '';

if (['production', 'preview'].includes(NEXT_PUBLIC_VERCEL_ENV)) {
  datadogRum.init({
    applicationId: '60e4af23-0265-4ccd-8b0b-eb44b8f0df01',
    clientToken: 'pube678e01f142f71e0bf16802cede0a9aa',
    site: 'datadoghq.com',
    service: process.env.NEXT_PUBLIC_DD_SERVICE,
    env: process.env.NEXT_PUBLIC_VERCEL_ENV,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
}

export default function Providers(props: {
  children: React.ReactNode;
  session: SessionProviderProps['session'];
  flagsConfig?: {
    bootstrap: IToggle[];
    context: IMutableContext;
  };
}) {
  const { children, session, flagsConfig } = props;

  // NOTE: Avoid useState when initializing the query client if you don't
  //       have a suspense boundary between this and the code that may
  //       suspend because React will throw away the client on the initial
  //       render if it suspends and there is no boundary
  const queryClient = getQueryClient();

  return (
    <FlagProvider config={flagsConfig}>
      <QueryClientProvider client={queryClient}>
        <SessionProvider session={session}>
          <AnalyticsProvider writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}>
            <UserflowProvider enabled={Boolean(session?.workspaceId)}>
              <ToastifyProvider>
                <ChatWithDataProvider>
                  <ContentItemActionsProvider>{children}</ContentItemActionsProvider>
                </ChatWithDataProvider>
              </ToastifyProvider>
            </UserflowProvider>
          </AnalyticsProvider>
        </SessionProvider>
      </QueryClientProvider>
    </FlagProvider>
  );
}
