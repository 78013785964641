import React, { createContext, useState } from 'react';
import { AdBreakdownModel } from '#/src/types/AdBreakdownModel';
import { AdBreakdownResult } from '#/src/types/AdBreakdownResult';

interface ChatWithDataContextType {
  model: AdBreakdownModel | null;
  visualizationData: any | null;
  tableData: AdBreakdownResult | null;
  templates: Record<string, string>;
  setTemplates: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export const ChatWithDataContext = createContext<ChatWithDataContextType | undefined>(undefined);

export const ChatWithDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [model, setModel] = useState<AdBreakdownModel>(null);
  const [templates, setTemplates] = useState<Record<string, string>>(null);

  return (
    <ChatWithDataContext.Provider
      value={{
        model,
        setModel,
        templates,
        setTemplates
      }}
    >
      {children}
    </ChatWithDataContext.Provider>
  );
};
